<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Cliente:</b> {{ customer.name }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>CPF/CNPJ:</b> {{ customer.cnpj }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Unidade de Medida:</b> {{ capacity ? capacity.name : 'Carregando...' }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Cidade:</b> {{ city ? city.name : 'Carregando...' }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Bairro:</b> {{ neighborhood ? neighborhood.name : 'Carregando...' }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Rota:</b> {{ region ? region.name : 'Carregando...' }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Endereço:</b> {{ customer.address }}
      </getecma-p>
    </div>
    <div v-if="customer.number" class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Número:</b> {{ customer.number }}
      </getecma-p>
    </div>
    <div v-if="customer.complement" class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Complemento:</b> {{ customer.complement }}
      </getecma-p>
    </div>
    <div v-if="customer.recurrence_id" class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Recorrência:</b> {{ recurrenceText ? recurrenceText : 'Carregando...' }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Status do Contrato:</b> {{ contractStatus }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Cliente por Ativação:</b> {{ activationStatus }}
      </getecma-p>
    </div>
  </div>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getCityById } from '@/modules/city/city.service';
import { getNeighborhoodById } from '@/modules/neighborhood/neighborhood.service';
import { getRegionById } from '@/modules/region/region.service';
import { getCapacityById } from '@/modules/capacity/capacity.service';
import { getRecurrenceById } from '@/modules/recurrence/recurrence.service';
import { FREQUENCIES } from '@/modules/recurrence/recurrence.constants';
import { STATUS_ACTIVATION, STATUS_CONTRACT } from '@/modules/customer/customer.constants';

export default {
  name: 'GetecmaRegionViewInformation',
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      city: null,
      neighborhood: null,
      region: null,
      capacity: null,
      recurrenceText: null,
    };
  },
  computed: {
    contractStatus() {
      return STATUS_CONTRACT[Number(this.customer.contract_status)];
    },
    activationStatus() {
      return STATUS_ACTIVATION[Number(this.customer.per_activation)];
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCityById(this.customer.city_id)
        .then(data => {
          this.city = data;
        })
        .catch(() => toastError('Erro ao obter cidade por ID'));

      getNeighborhoodById(this.customer.neighborhood_id)
        .then(data => {
          this.neighborhood = data;
        })
        .catch(() => toastError('Erro ao obter bairro por ID'));

      getRegionById(this.customer.region_id)
        .then(data => {
          this.region = data;
        })
        .catch(() => toastError('Erro ao obter rota por ID'));

      getCapacityById(this.customer.capacity_id)
        .then(data => {
          this.capacity = data;
        })
        .catch(() => toastError('Erro ao obter unidade de medida por ID'));

      if (this.customer.recurrence_id) {
        getRecurrenceById(this.customer.recurrence_id)
          .then(data => {
            this.recurrenceText = `${data.number} ${Object.values(FREQUENCIES)[data.frequency - 1].value}`;
          })
          .catch(() => toastError('Erro ao obter recorrência por ID'));
      }
    },
  },
};
</script>
